import launch from "./../../../Assets/images/launch.jpg";
import invest from "./../../../Assets/images/invest.jpg";
import trade from "./../../../Assets/images/trade.jpg";
import automate from "./../../../Assets/images/automate.jpg";
import Heading3 from "../../Components/Heading/Heading3";
import React from 'react';
import Featuresdescription from "./Featuresdescription";
function FeatureBox(props) {
  
  return (
    <div className={`feature_box ${props.class}`}>
      <h2>{props.number}</h2>
      <span>{props.feature}</span>
    </div>
  );
}
// function FeatureDeescription(props) {
//     return <div className={`feature_desc_box ${props.class}`}>
//            <h2>{props.number}</h2>
//            <span>{props.feature}</span>
//        </div>
//    }
export default function Features() {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  // return (
  //   <div
  //     className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
  //     ref={domRef}
  //   >
  //     {props.children}
  //   </div>
  // );

  return (
    <>
   
      <section className="features">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
              <div className="heading-wraper justify-content-center">
              <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef} >
                <Heading3
                  title="Levels"
                  back="true"
                  backcolor="bg_darkblue"/>
                </div>
              </div>
            </div>
            <div className="feature_container">
            <div className={`fade-in-section1 ${isVisible ? 'is-visible' : ''}`} ref={domRef} >
              <div className="row">
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-12">
                  <a href="#first"><FeatureBox number="1" feature="Launch" class="bg_pink" /></a>
                </div>
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-12">
                 <a href="#second"> <FeatureBox number="2" feature="Invest" class="bg_blue" /></a>
                </div>
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-12">
                  <a href="#third"><FeatureBox number="3" feature="Trade" class="bg_yellow" /></a>
                </div>
                <div className="col-lg-3 col-md-6 col-xl-3 col-sm-12">
                 <a href="#fourth"> <FeatureBox
                    number="4"
                    feature="Automate"
                    class="bg_lightwhite feature_blue"
                  /></a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      
      </section>

  <Featuresdescription></Featuresdescription>
      
    </>
  );
}
