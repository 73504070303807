import React from 'react';
import Heading9 from "../../Components/Heading/Heading9";
import Heading3 from '../../Components/Heading/Heading3';
import icon1 from './../../../Assets/images/why1.svg';
import icon2 from './../../../Assets/images/why2.svg';
import icon3 from './../../../Assets/images/why3.svg';
import icon4 from './../../../Assets/images/why4.svg';
function ArcadeBox(props){
    return <div className="why_arcade_box">
        <div className="arcade_box_img">
            <img src={props.image} alt="" />
        </div>
        <div className="arcade_box_content">
            <p className='paragraph_md text_darkblue'>{props.content}</p>
        </div>
    </div>
}

export default function WhyArcade() {
    const [isVisible, setVisible] = React.useState(false);
    const domRef = React.useRef();
    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting));
      });
      observer.observe(domRef.current);
    }, []);
    
    return <div className='whyarcade'>
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''}`} ref={domRef} >
        <div className="container">
            <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xl-12'>
            <div className="heading-wraper mb-5">
                <Heading9 title="Why Arcade?" back="true" backcolor="bg_blue" headclass="blue_heading" />
            </div>
          
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xl-12'>
            <div className="why_row">
                <div className='col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-5'>
                <ArcadeBox image={icon1} content="We will be the first dedicated launchpad for GameFi projects to provide a first class user-experience that gamers understand."/>
                </div>
                <div className='col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-5'>
                <ArcadeBox image={icon2} content="A single unified interface where gamers are able to track their game assets across blockchains."/>
                </div>
                <div className='col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-5'>
                <ArcadeBox image={icon3} content="Robust GameFi Vault protocols"/>
                </div>
                <div className='col-lg-6 col-md-6 col-xl-6 col-sm-12 mb-5'>
                <ArcadeBox image={icon4} content="Innovative GameFi fractionalization and yield optimisation automation protocols."/>
            </div>
            </div>
            </div>
            </div>
        </div>
        </div>
    </div>;
}
