import React from "react";
import Heading3 from "../../Components/Heading/Heading3";
import Heading9 from "../../Components/Heading/Heading9";
import { Tab, TabPanel, Tabs, TabList } from "react-web-tabs";
import "react-web-tabs/dist/react-web-tabs.css";

export default function Roadmap() {
  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  // Roadmap-Animation start
  (function () {
    "use strict";
  
    // define variables
    var items = document.querySelectorAll(".timeline li");
  
    // check if an element is in viewport
    // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  
    function callbackFunc() {
      for (var i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          items[i].classList.add("in-view");
        }
      }
    }
  
    // listen for events
    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);
  })();
  // Roadmap-Animation End
  
  return (
    <div className="roadmap">
      <div className="container">
      <div className={`fade-in-section ${isVisible ? "is-visible" : ""}`}ref={domRef}>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
            <div className="heading-wraper">
                <Heading9
                  title="Roadmap"
                  back="true"
                  backcolor="bg_pink"
                  headclass="blue_heading"
                />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
              <div className="roadmap_box" style={{ padding: "50px 20px" }}>
                
                <div class="timeline">
                  <ul>
                    <li>
                      <div class="time">
                        <span>January 2022 Phase 0</span>
                      </div>
                      <div class="content">
                        <h3>Community</h3>
                       <ul>
                       <p>Discord</p>
                       <p>Twitter</p>
                       <p>Whitepaper</p>
                       <p>Website</p>
                       </ul>
                      </div>
                    </li>
                    <li className="cir">
                      <div class="time">
                        <span>March 2022 Phase 0.5</span>
                      </div>
                      <div class="content">
                        <h3>IDO</h3>
                        <p>Token IDO</p>
                        <p>Staking of tokens to earn rewards</p>
                        <p>Airdrop ARCADE tokens to the community</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>May 2022 Phase 1.0</span>
                      </div>
                      <div class="content">
                        <h3>IGO Launchpad</h3>
                        <p> Release of IGO Launch platform</p>
                        <p>Security Audit</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>July 2022 Phase 1.5</span>
                      </div>
                      <div class="content">
                        <h3>Yield Farming</h3>
                        <p>Auto-compounding vaults</p>
                        <p> GameFi investment vaults</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>October 2022 Phase 2.0</span>
                      </div>
                      <div class="content">
                        <h3>Marketplace</h3>
                        <p>Marketplace for Harmony GameFi Assets</p>
                      <p>Trading and selling of game assets via ARCADE marketplace for select blockchain games</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>December 2022 Phase 2.5</span>
                      </div>
                      <div class="content">
                        <h3>Decentralization</h3>
                        <p>DAO Governance</p>
                      <p>Community driven development initiatives, grants and bounties</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>March 2023 Phase 3.0</span>
                      </div>
                      <div class="content">
                        <h3>Cross-chain Compatibility</h3>
                     <p>Cross-chain NFT functionality in EVM (Ethereum Virtual Machine) compatible networks</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>June 2023 Phase 3.5</span>
                      </div>
                      <div class="content">
                        <h3>Automation Platform</h3>
                   <p>Release of automation platform for ARCADE users</p>
                      </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>October 2023 Phase 4.0</span>
                      </div>
                      <div class="content">
                        <h3>Web2 To Web3 Bridge</h3>
                        <p>Fiat Gateway</p>
                        <p>iOS App</p>
                        <p>Android App</p>
                              </div>
                    </li>
                    <li>
                      <div class="time">
                        <span>November 2023 Phase 4.5</span>
                      </div>
                      <div class="content">
                        <h3>Multi-chain Compatibility</h3>
                 <p>Cross-chain functionality in non EVM networks</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
}
