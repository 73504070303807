import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { injected } from './connector';
import { useWeb3React } from '@web3-react/core';

export const MetaMaskContext = React.createContext(null)

export const MetaMaskProvider = ({ children }) => {

    const { activate, account, library, active, deactivate } = useWeb3React()

    const [isActive, setIsActive] = useState(false);
    const [walletModal, setWalletModal] = useState(false);
    const [shouldDisable, setShouldDisable] = useState(false); // Should disable connect button while connecting to MetaMask
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            var isConnected = await sessionStorage.getItem("isConnected");
            if (isConnected) {
                connect().then(val => {
                    setIsLoading(false);
                })
            }
        }
        fetchData()
    },[])

    const handleIsActive = useCallback(() => {
        setIsActive(active);
    }, [active])

    const handleWalletModal = async (state) => {
        console.log("state ===>" + state);
        setWalletModal(state);
    }

    useEffect(() => {
        handleIsActive();
    }, [handleIsActive])


    const connect = async () => {
        setShouldDisable(true)
        try {
            console.log('----1--')
            // if (chainId !== 4) {
            //     notify(true, "switch to rinkby network on metamask");
            // }

            await activate(injected).then(() => {
                setShouldDisable(false)
                // localStorage.setItem("providerType", 'metaMask');
                sessionStorage.setItem("isConnected", true);
            })

            setWalletModal(false);
        } catch (error) {
            console.log('Error on connecting: ', error);
        }
    }

    const disconnect = async () => {
        try {
            await deactivate()
            sessionStorage.removeItem("isConnected");
            window.location.reload();
            // localStorage.removeItem("providerType");
        } catch (error) {
            console.log('Error on disconnnect: ', error);
        }
    }

    const values = useMemo(() => ({
        isActive,
            account,
            isLoading,
            walletModal,
            handleWalletModal,
            connect,
            disconnect,
            library,
            shouldDisable
    }), [isActive, isLoading, shouldDisable, account, walletModal])

    return <MetaMaskContext.Provider value={values}>{children}</MetaMaskContext.Provider>
}

export default function useMetaMask() {
    const context = React.useContext(MetaMaskContext)
    if (context === undefined) {
        throw new Error('useMetaMask hook must be used with a MetaMaskProvider component')
    }

    return context
}