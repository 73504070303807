import React , {useState}from "react";
import Time from "../../Components/Time/Time";
import Heading15 from "../../Components/Heading/Heading15";
import Heading2 from "../../Components/Heading/Heading2";
import Countdown, { zeroPad } from 'react-countdown';
export default function Hero() {

  const [day, setDay] = useState()
  const [hour, setHour] = useState()
  const [minute, setMinute] = useState()
  const [second, setSecond] = useState()
  const [complete, setComplete] = useState()
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    console.log('completed', completed)
    setDay(days)
    setHour(hours)
    setMinute(minutes)
    setSecond(seconds)
    setComplete(completed)

    return <>{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</>;
    // }
  };

  return (
    <section className="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
            {/* <h2 className='heading_lg text_white mt-4 heading_blue_border'>Insert Coin</h2> */}
            <Heading15 title="Insert Coin" back="true" />
            <Heading2 title=" for Endless" back="" />
            <Heading2 title="Opportunities" />
            <h2 className="heading_lg text_white mt-4 heading_pink_border"></h2>
            <p className="mt-3 paragraph">
            Invest in the Metaverse from as low as $10{" "}
            </p>
            {/* <div style={{display:'none'}}>
            <Countdown
                date={1648209600000}
                zeroPadTime={1}
                renderer={renderer}
              />
              </div>
            <div className="start_soon_box">
             <ul>
               <li>
                 <h1>{day}</h1>
                 <p>days</p>
               </li>
               <li>
                 <h1>{hour}</h1>
                 <p>Hours</p>
               </li>
               <li>
                 <h1>{minute}</h1>
                 <p>Minutes</p>
               </li>
               <li>
                 <h1>{second}</h1>
                 <p>Secondes</p>
               </li>
             </ul>
           </div>
           {complete == true ? (<><button type="button" className="start_soon_btn" >Start Soon</button></>) : (<><button type="button" className="start_soon_btn" disabled >Start Soon</button></>)} */}
         
         <a href="https://discord.com/invite/GM33UdVjm8" style={{textDecoration:'none'}} target="_blank"> <button type="button" className="start_soon_btn" style={{color:'#350272'}} >join us on Discord</button></a>
          </div>
        </div>
      </div>
    </section>
  );
}
