import React, { useState } from "react";
import Time from "../../Components/Time/Time";
import Heading from "../../Components/Heading/Heading";
import Heading2 from "../../Components/Heading/Heading2";
import Countdown, { zeroPad } from "react-countdown";

export default function StartSoon() {
  const [day, setDay] = useState();
  const [hour, setHour] = useState();
  const [minute, setMinute] = useState();
  const [second, setSecond] = useState();
  const [complete, setComplete] = useState();
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    console.log("completed", completed);
    setDay(days);
    setHour(hours);
    setMinute(minutes);
    setSecond(seconds);
    setComplete(completed);

    return (
      <>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </>
    );
    // }
  };

  const [isVisible, setVisible] = React.useState(false);
  const domRef = React.useRef();
  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);

  return (
    <section className="startsoon">
      <div
        className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
        ref={domRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
              <Heading2 title="Excited? Want in?" back="" />
              <Heading2 title="Get Airdrops," back="" />
              <Heading2 title="Whitelist and More!" back="" />
              {/* <div style={{display:'none'}}>
            <Countdown
                date={1648209600000}
                zeroPadTime={1}
                renderer={renderer}
              /></div> */}
              {/* <div className="start_soon_box">
             <ul>
               <li>
                 <h1>{day}</h1>
                 <p>days</p>
               </li>
               <li>
                 <h1>{hour}</h1>
                 <p>Hours</p>
               </li>
               <li>
                 <h1>{minute}</h1>
                 <p>Minutes</p>
               </li>
               <li>
                 <h1>{second}</h1>
                 <p>Secondes</p>
               </li>
             </ul>
           </div> */}
            </div>
            {/* <div className='col-lg-12 col-md-12 col-sm-12 col-xl-12'>
            <div className="start_soon_main">
            {complete == true ? (<><button type="button" className="start_soon_btn" >Start Soon</button></>) : (<><button type="button" className="start_soon_btn" disabled >Start Soon</button></>)}
            </div>
            </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
              <div className="start_soon_main">
              <a href="https://discord.com/invite/GM33UdVjm8" style={{textDecoration:'none'}} target="_blank">
              <button type="button" className="start_soon_btn" style={{color: 'rgb(53, 2, 114)'}}>
                     join us on Discord
                    </button>
                    </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
